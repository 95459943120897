<template lang="pug">
  v-container#inv(fluid='' tag='section' style="padding: 0px;")
    v-dialog(v-model='changeStatConfirmDialog' persistent max-width='290')
      v-card
        v-card-title Confirmar
        v-card-text(style='text-align: center;')
          p ¿Estás seguro de cambiar el estado de este producto?
        v-card-actions
          v-spacer(style="text-align: right;")
            v-btn(color='red darken-1' text='' @click='changeStatConfirmDialog = false')
              | Cancelar
            v-btn.success-btn(text='' @click='AcceptStatConfirmDialog')
              | Aceptar
    v-dialog(v-if="dialogImport" v-model='dialogImport' max-width="600px")
      v-card(style='padding-bottom:0;')
        v-toolbar(text='' color='primary' dark='')
          v-toolbar-title
            h4 Importar Productos
            v-btn(icon="" dark="" @click="dialogImport = false" style="position: absolute; top: 0px; right: 10px;")
              v-icon.primary.rounded mdi-close
        v-card-text(style='padding-bottom: 0;')
          div(v-if="$store.state.profile.profileLoaded" style="widht: 100% text-align: center;")
            a(v-if="!$store.state.profile.profileLoaded.productPrice" class="download-excel" href="https://firebasestorage.googleapis.com/v0/b/j2rinventor.appspot.com/o/formatos%2Fformato-importar-productos.xlsx?alt=media&token=4105d01d-f977-46e9-b5d0-c102226d78ab") Descargar Plantilla para Carga de Productos
            a(v-if="$store.state.profile.profileLoaded.productPrice" class="download-excel" href="https://firebasestorage.googleapis.com/v0/b/j2rinventor.appspot.com/o/formatos%2Fformato-importar-productos-mayorista.xlsx?alt=media&token=41e465aa-7428-4b5e-a918-eccb84f67b4d") Descargar Plantilla para Carga de Productos
            p
              strong(style="font-weight: bold;") CÓDIGOS DE IMPLEMENTACIÓN:
            v-row
              v-col(cols="12" md="6")
                p
                  strong(style="font-weight: bold;") Tarifas del IVA
                p(style="margin-bottom: 0px;") IVA Cero -> 0
                p(style="margin-bottom: 0px;") IVA 12% -> 2
                p(style="margin-bottom: 0px;") IVA 14% -> 3
                p(style="margin-bottom: 0px;") IVA 15% -> 4
                p(style="margin-bottom: 0px;") IVA 5% -> 5
                p(style="margin-bottom: 0px;") No Objeto de Impuesto -> 6
                p(style="margin-bottom: 0px;") Exento de IVA -> 7
                p(style="margin-bottom: 0px;") IVA diferenciado -> 8
                p(style="margin-bottom: 0px;") IVA 13% -> 10
              v-col(cols="12" md="6")
                p
                  strong(style="font-weight: bold;") Tipo de Producto
                p(style="margin-bottom: 0px;") Bien -> 01
                p(style="margin-bottom: 0px;") Servicio -> 02
            input#inputExcel(type='file' style="padding-top: 10px; font-family: 'ceraProMedium', sans-serif !important;")
          v-row(style='align-items: center;')
            v-col(cols='12')
              p(style="margin-bottom: 0px;") Para asistencia contáctanos:
                v-btn.ml-2(min-width='0' text='' style="" @click="openLink('https://wa.me/+593984530998')")
                  v-icon(color="black") mdi-whatsapp
            //v-col(cols='6' style='text-align:end;')
              v-btn.ml-2(min-width='0' text='' style='' @click="openLink('https://www.facebook.com/dental.prime.391/')")
                v-icon(color="black") mdi-facebook
              v-btn.ml-2(min-width='0' text=''  style="" @click="openLink('https://www.instagram.com/dentalprimeapp/')")
                v-icon(color="black") mdi-instagram
        v-card-actions
          v-spacer
          v-btn.success-btn(text='' @click='dialogImport = false' :loading='loadingSaveProduct')
            | Finalizar
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-dialog(fullscreen="" v-model='dialodTransaction')
      v-card
        transactionDataModal(@closeModal="closeTransactionModal" :productToTransaction='productToTransaction' @productToInventory='productToInventory = $event')
    v-dialog(fullscreen="" v-model='dialodInvMod')
      v-card
        inventoryDataModal(@closeModal="closeInventoryModal" :productToEdit='productToEdit' :forEditProduct='forEditProduct')
    base-material-card.px-5.py-3(icon='mdi-view-list' :title="'Lista de Productos ' + (productsTotal ? ' TOTAL SIN IVA: $' + productsTotal : '')" style='min-height: 100vh;')
      v-container#inventory-table(fluid='' tag='section')
        v-row
          v-col(cols="12" md="4")
            v-text-field(@keyup="filterDataTable" v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
          v-col(cols="12" md="2")
            v-select(@change="filterData" v-model='selectTypeFilter' return-object :items='typeFilter' label='Tipo de listado' item-text='text')
          v-col(cols="12" md="2")
            v-btn.mr-0(color='primary' rounded='' @click='addNewProduct' style="width: 100%;")
              strong
                | NUEVO
                br
                | PRODUCTO
          v-col(cols="12" md="2")
            v-btn.mr-0(color='green' rounded='' @click='importProducts' style="width: 100%;")
              strong
                | IMPORTAR
                br
                | PRODUCTOS
          v-col(cols="12" md="2")
            v-btn.mr-0(color='secondary' rounded='' @click='downLoadExcelFile' style="width: 100%;")
              strong
                | DESCARGAR
                br
                | LISTA
      v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
      v-data-table.mx-2#tablaDatos(v-if="$store.state.profile.profileLoaded" :headers="$store.state.profile.profileLoaded.productPrice ? headersWholesaler : headers" v-show="!firstLoad" :items="productsLstFilter" item-key="uid" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Productos por página'\}")
        template(v-slot:[`item.codArticleView`]='{ item }')
          label(:style="getColorQuantity(item)") {{item.codArticle}}
        template(v-slot:[`item.nameView`]='{ item }')
          label(:style="getColorQuantity(item)") {{item.name}}
        template(v-slot:[`item.typeView`]='{ item }')
          label(:style="getColorQuantity(item)") {{item.type.texto}}
        template(v-slot:[`item.priceView`]='{ item }')
          label(:style="getColorQuantity(item)") $ {{ parseFloat(item.activeValue).toFixed(3) }}
        template(v-slot:[`item.priceWholesalerView`]='{ item }')
          label(:style="getColorQuantity(item)") $ {{ parseFloat(item.activeValueWholesaler ? item.activeValueWholesaler : '0.00').toFixed(3) }}
        template(v-slot:[`item.total_value`]='{ item }')
          label(:style="getColorQuantity(item)") $ {{ item.totalValue === 'NaN' || item.totalValue === null  ? '0' : (item.type.codigo.toString() === '02' ? (parseFloat(item.activeValue) * 1).toFixed(3) : parseFloat(item.totalValue).toFixed(3)) }}
        template(v-slot:[`item.total_value_iva`]='{ item }')
          label(:style="getColorQuantity(item)") {{ (item.tarifaiva.codigo.toString() === '0' || item.tarifaiva.codigo.toString() === '6' || item.tarifaiva.codigo.toString() === '7') ? '' : (item.type.codigo.toString() === '02' ? '$ ' + (parseFloat(item.activeWValue) * 1).toFixed(3) : '$ ' + (parseFloat(item.activeWValue) * parseFloat(item.quantity)).toFixed(3)) }}
        template(v-slot:[`item.status`]='{ item }')
          v-chip(:color="getColor(item.status)" small label) {{ item.status ? item.status : 'ACTIVO' }}
        template(v-slot:[`item.quantityView`]='{ item }')
          label(:style="getColorQuantity(item)") {{ item.type.codigo.toString() === '02' ? 1 : item.quantity }}
        template(v-slot:item.action="{ item }")
          v-row
            v-col(cols="4" md="4")
              v-tooltip(v-if="!item.status || item.status === 'ACTIVO'" bottom="")
                template(v-slot:activator="{ on }")
                  v-btn(text="" icon="" color='red' v-on="on" @click="changeProductState(item)")
                    v-icon mdi-led-off
                span.tooltips Desactivar
              v-tooltip(v-if="item.status === 'INACTIVO'" bottom="")
                template(v-slot:activator="{ on }")
                  v-btn(text="" icon="" color='green' v-on="on" @click="changeProductState(item)")
                    v-icon mdi-led-on
                span.tooltips Activar
            v-col(cols="4" md="4")
              v-tooltip(bottom="")
                template(v-slot:activator="{ on }")
                  v-btn(text="" icon="" color='blue' v-on="on" @click="editProduct(item)")
                    v-icon mdi-lead-pencil
                span.tooltips Editar Producto
            v-col(cols="4" md="4")
              v-tooltip(bottom="")
                template(v-slot:activator="{ on }")
                  v-btn(text="" icon="" color='green' v-on="on" @click="transactionProduct(item)")
                    v-icon mdi-transfer
                span.tooltips Ajustar inventario
        //- template(v-slot:item.delete="{ item }")
        //-   v-tooltip(bottom="")
        //-     template(v-slot:activator="{ on }")
        //-       v-btn(text="" icon="" color='red' v-on="on" @click="deleteProduct(item)")
        //-         v-icon(small) mdi-trash-can-outline
        //-     span Borrar Producto
        //- Mensaje de alerta cuando no hay resultados
        v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
          | Sin resultados
        v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
          | Sin datos
        v-flex(xs12='', sm10='', offset-sm1='')
</template>

<script>
  import readXlsxFile from 'read-excel-file'
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import * as functions from '../../../functions.js'
  import * as vars from '../../../vars.json'

  export default {
    components: {
      InventoryDataModal: () => import('@/views/dashboard/component/application/InventoryDataModal'),
      TransactionDataModal: () => import('@/views/dashboard/component/application/TransactionDataModal'),
    },
    data: () => ({
      newTarifaIva: '',
      typeFilter: [
        {
          code: '001',
          text: 'TODOS',
        },
        {
          code: '002',
          text: 'INVENTARIO POSITIVO',
        },
        {
          code: '003',
          text: 'INVENTARIO NEGATIVO',
        },
      ],
      selectTypeFilter: {
        code: '001',
        text: 'TODOS',
      },
      producToChangeStatus: {},
      changeStatConfirmDialog: false,
      loadingSaveProduct: false,
      dialogImport: false,
      forEditProduct: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      productToInventory: {},
      productToTransaction: {},
      productToEdit: {
        codArticle: '',
        barcode: '',
        name: '',
        description: '',
        type: {
          codigo: '01',
          texto: 'BIEN',
        },
        quantity: '1',
        activeValue: '',
        activeWValue: '',
        activeValueWholesaler: '',
        activeWValueWholesaler: '',
        purchaseValue: '',
        purchaseWValue: '',
        productKey: '',
        completeData: '',
        tarifaiva: {
          codigo: '0',
          texto: '0%',
          valor: '0',
        },
        irbpnr: '',
        tarifaice: '',
        totalValue: '',
        inecCode: null,
        lstProviderPrice: [],
        branch: null,
      },
      headers: [
        {
          text: 'Cod. Artículo',
          value: 'codArticleView',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Nombre',
          value: 'nameView',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Tipo',
          value: 'typeView',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Cantidad',
          value: 'quantityView',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Costo sin IVA',
          value: 'priceView',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Valor Total sin IVA',
          value: 'total_value',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Valor Total con IVA',
          value: 'total_value_iva',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Estado',
          value: 'status',
          align: 'left',
          filterable: true,
        },
        { text: 'ACCIONES', align: 'center', value: 'action', sortable: false },
      ],
      headersWholesaler: [
        {
          text: 'Cod. Artículo',
          value: 'codArticleView',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Nombre',
          value: 'nameView',
          align: 'left',
          filterable: true,
        },
        // { text: 'Descripción', value: 'description', align: 'left', filterable: true },
        {
          text: 'Tipo',
          value: 'typeView',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Cantidad',
          value: 'quantityView',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Costo sin IVA',
          value: 'priceView',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Costo Mayorista',
          value: 'priceWholesalerView',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Valor Total sin IVA',
          value: 'total_value',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Valor Total con IVA',
          value: 'total_value_iva',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Estado',
          value: 'status',
          align: 'left',
          filterable: true,
        },
        { text: 'ACCIONES', align: 'center', value: 'action', sortable: false },
      ],
      ordenarPor: 'codArticle',
      search: '',
      cargando: false,
      firstLoad: false,
      dialodInvMod: false,
      dialodTransaction: false,
      productsLstFilter: [],
    }),
    computed: {
      productsLst () {
        return this.$store.state.product.lstProducts
      },
      productsTotal () {
        return this.$store.state.product.totalProducts
      },
    },
    watch: {
      productsLst () {
        this.productsLstFilter = this.productsLst
        this.selectTypeFilter = {
          code: '001',
          text: 'TODOS',
        }
      },
      async productToInventory () {
        await this.$store.dispatch('product/setProduct', [this.productToInventory.productKey, this.productToInventory]).then(async resolve => {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Producto actualizado correctamente',
          }
          this.$emit('closeModal')
        }, reject => {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al guardar el producto',
          }
        })
      },
    },
    mounted () {
      this.newTarifaIva = vars.validIva
      this.productsLstFilter = this.$store.state.product.lstProducts
      this.selectTypeFilter = {
        code: '001',
        text: 'TODOS',
      }
      this.getLstInec()
    },
    methods: {
      filterDataTable () {
        this.productsLstFilter = this.productsLst.filter((item) => {
          return (
            (item.codArticle.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(this.search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase())) ||
            (item.name.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(this.search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()))
          )
        })
      },
      filterData () {
        if (this.selectTypeFilter.code === '001') {
          this.productsLstFilter = this.productsLst
        }
        if (this.selectTypeFilter.code === '002') {
          this.productsLstFilter = this.productsLst.filter(item => {
            return item.quantity.toString().trim() === '' || parseFloat(item.quantity) >= 0
          })
        }
        if (this.selectTypeFilter.code === '003') {
          this.productsLstFilter = this.productsLst.filter(item => {
            return parseFloat(item.quantity) < 0
          })
        }
      },
      getColor (valor) {
        if (!valor || valor === 'ACTIVO') {
          return 'green'
        } else {
          return 'red'
        }
      },
      getColorQuantity (item) {
        if (item.type.codigo.toString() === '02') {
          return 'color: black !important; font-size: 14px; !important'
        }
        if (item.quantity.toString().trim() !== '') {
          if (parseFloat(item.quantity) >= 0) {
            return 'color: black !important; font-size: 14px; !important'
          } else {
            return 'color: red !important; font-size: 14px !important; font-weight: bold !important;'
          }
        } else {
          return 'color: black !important; font-size: 14px; !important'
        }
      },
      async getLstInec () {
        if (!this.$store.state.facturacion.tokenAdmin) {
          await this.$store.dispatch('facturacion/adminLoginFac')
        }
        const data = {
          token: this.$store.state.facturacion.tokenAdmin,
          filterData: '',
        }
        this.$store.dispatch('facturacion/getInecCodes', data)
      },
      openLink (link) {
        window.open(`${link}`, '_blank')
      },
      importProducts () {
        this.dialogImport = true
        setTimeout(() => {
          const inputExcel = document.getElementById('inputExcel')
          inputExcel.addEventListener('change', () => {
            readXlsxFile(inputExcel.files[0]).then(async (rows) => {
              this.loadingSaveProduct = true
              const productsLst = []
              let globalBan = true
              for (let i = 1; i < rows.length; i++) {
                let ban = true
                let uidVal = ''
                let msg = 'El campo {CAMPO} de la fila {FILA} es incorrecto. Por favor ingrese el campo indicado para continuar.'
                if (!rows[i][0] || rows[i][0].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Código')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }
                if (!rows[i][2] || rows[i][2].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Nombre')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }
                if (!rows[i][3] || rows[i][3].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Descripción')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                if (!rows[i][4] || rows[i][4].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Costo Unitario sin IVA')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                const val = rows[i][6].toString()
                if (!val || val.trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Tarifa del IVA')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                if (!rows[i][7] || rows[i][7].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Tipo de Producto')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                if (this.$store.state.profile.profileLoaded.productPrice) {
                  if (!rows[i][8] || rows[i][8].toString().trim() === '') {
                    msg = msg.toString().replace('{CAMPO}', 'Costo Mayorista sin IVA')
                    msg = msg.toString().replace('{FILA}', (i + 1))
                    ban = false
                  }
                }

                let txtTypeProducto = ''
                if (ban) {
                  if (rows[i][7].toString() === '01') {
                    if (!rows[i][5]) {
                      if (rows[i][5].toString().trim() === '') {
                        msg = msg.toString().replace('{CAMPO}', 'Cantidad')
                        msg = msg.toString().replace('{FILA}', (i + 1))
                        ban = false
                      }
                    }
                    txtTypeProducto = 'BIEN'
                  } else {
                    rows[i][5] = '1'
                    txtTypeProducto = 'SERVICIO'
                  }
                }

                const productValid = this.productsLst.filter(
                  item => {
                    const codeProduct = rows[i][0] ? rows[i][0] : ''
                    return (item.codArticle === codeProduct)
                  })
                if (productValid.length > 0) {
                  uidVal = productValid[0].id
                }

                if (ban) {
                  let textoSend = ''
                  let codigoSend = ''
                  let valorSend = ''
                  switch (rows[i][6].toString()) {
                    case '0':
                      textoSend = '0%'
                      codigoSend = '0'
                      valorSend = '0'
                      break
                    case '2':
                      textoSend = '12%'
                      codigoSend = '2'
                      valorSend = '12'
                      break
                    case '3':
                      textoSend = '14%'
                      codigoSend = '3'
                      valorSend = '14'
                      break
                    case '4':
                      textoSend = '15%'
                      codigoSend = '4'
                      valorSend = '15'
                      break
                    case '5':
                      textoSend = '5%'
                      codigoSend = '5'
                      valorSend = '5'
                      break
                    case '6':
                      textoSend = 'No Objeto de Impuesto'
                      codigoSend = '6'
                      valorSend = '0'
                      break
                    case '8':
                      textoSend = 'IVA diferenciado'
                      codigoSend = '8'
                      valorSend = '0'
                      break
                    case '10':
                      textoSend = '13%'
                      codigoSend = '10'
                      valorSend = '13'
                      break
                    default:
                      textoSend = 'Exento de IVA'
                      codigoSend = '7'
                      valorSend = '0'
                  }

                  let nameSend = rows[i][2] ? rows[i][2] : ''

                  if (nameSend !== '') {
                    if (nameSend.length > 150) {
                      nameSend = nameSend.substr(0, 149)
                    }
                  }

                  let descriptionSend = rows[i][3] ? rows[i][3] : ''

                  if (descriptionSend !== '') {
                    if (descriptionSend.length > 300) {
                      descriptionSend = descriptionSend.substr(0, 299)
                    }
                  }

                  let activeValueWholesaler = '0.000'
                  if (this.$store.state.profile.profileLoaded.productPrice) {
                    activeValueWholesaler = rows[i][8] ? (rows[i][8]).toString().replace(',', '.') : '0.000'
                  }

                  const productSend = {
                    codArticle: rows[i][0] ? rows[i][0] : '',
                    barcode: rows[i][1] ? rows[i][1] : '',
                    name: nameSend,
                    description: descriptionSend,
                    type: {
                      codigo: rows[i][7] ? rows[i][7] : '',
                      texto: txtTypeProducto,
                    },
                    quantity: rows[i][5] ? rows[i][5] : '',
                    activeValue: rows[i][4] ? rows[i][4] : '',
                    activeValueWholesaler: activeValueWholesaler,
                    productKey: '',
                    completeData: '',
                    tarifaiva: {
                      codigo: codigoSend,
                      texto: textoSend,
                      valor: valorSend,
                    },
                    irbpnr: '',
                    tarifaice: '',
                    totalValue: '',
                    validChangeIva: true,
                  }
                  if (uidVal !== '') {
                    productSend.productKey = uidVal
                    productsLst.push(productSend)
                  } else {
                    productsLst.push(productSend)
                  }
                } else {
                  globalBan = false
                  this.dialogImport = false
                  this.loadingSaveProduct = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: msg,
                  }
                  return
                }
              }
              if (globalBan) {
                let banEnd = true
                let correctRegister = 0
                let errorRegister = 0
                if (productsLst.length > 0) {
                  for (let i = 0; i < productsLst.length; i++) {
                    if (!productsLst[i].productKey || productsLst[i].productKey === '') {
                      productsLst[i].productKey = firebase.database().ref(`clients/${this.currentUserId}`).push().getKey()
                    }
                    productsLst[i].activeValue = parseFloat(productsLst[i].activeValue.toString().replace(',', '.'))
                    if (parseFloat(productsLst[i].tarifaiva.valor) > 0) {
                      productsLst[i].activeWValue = (parseFloat(productsLst[i].activeValue) * (1 + (parseFloat(productsLst[i].tarifaiva.valor) / 100))).toFixed(4).replace(',', '.')
                    } else {
                      productsLst[i].activeWValue = productsLst[i].activeValue
                    }
                    if (this.$store.state.profile.profileLoaded.productPrice) {
                      if (parseFloat(productsLst[i].tarifaiva.valor) > 0) {
                        productsLst[i].activeWValueWholesaler = (parseFloat(productsLst[i].activeValueWholesaler) * (1 + (parseFloat(productsLst[i].tarifaiva.valor) / 100))).toFixed(4).replace(',', '.')
                      } else {
                        productsLst[i].activeWValueWholesaler = productsLst[i].activeValueWholesaler
                      }
                    }
                    productsLst[i].totalValue = parseFloat(parseFloat(productsLst[i].quantity) * parseFloat(productsLst[i].activeValue)).toFixed(3)
                    productsLst[i].completeData = productsLst[i].codArticle + ' | ' + productsLst[i].name + ' | ' + productsLst[i].description + ' | ' + productsLst[i].barcode
                    await this.$store.dispatch('product/setProduct', [productsLst[i].productKey, productsLst[i]]).then(resolve => {
                      correctRegister++
                    }, reject => {
                      banEnd = false
                      errorRegister++
                    })
                  }
                  if (banEnd) {
                    this.dialogImport = false
                    this.loadingSaveProduct = false
                    this.snackbar = {
                      show: true,
                      color: 'green',
                      text: 'Proceso finalizado con: ' + correctRegister + ' registros correctos y ' + errorRegister + ' registros incorrectos.',
                    }
                  } else {
                    this.dialogImport = false
                    this.loadingSaveProduct = false
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Proceso finalizado con: ' + correctRegister + ' registros correctos y ' + errorRegister + ' registros incorrectos.',
                    }
                  }
                } else {
                  this.dialogImport = false
                  this.loadingSaveProduct = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'No se tiene registros para agregar.',
                  }
                }
              }
            }).catch((e) => {
              this.dialogImport = false
              this.loadingSaveProduct = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de cargar el archivo. Verifique el archivo.',
              }
            })
          })
        }, 500)
      },
      transactionProduct (itemTransaction) {
        this.dialodTransaction = true
        this.productToTransaction = itemTransaction
      },
      editProduct (itemToEdit) {
        this.forEditProduct = true
        this.dialodInvMod = true
        this.productToEdit = itemToEdit
      },
      changeProductState (itemToChangStat) {
        this.changeStatConfirmDialog = true
        this.producToChangeStatus = itemToChangStat
      },
      AcceptStatConfirmDialog () {
        this.changeStatConfirmDialog = false
        if (this.producToChangeStatus.status) {
          switch (this.producToChangeStatus.status) {
            case 'ACTIVO':
              this.producToChangeStatus.status = 'INACTIVO'
              break
            case 'INACTIVO':
              this.producToChangeStatus.status = 'ACTIVO'
              break
            default:
          }
        } else {
          this.producToChangeStatus.status = 'INACTIVO'
        }
        this.$store.dispatch('product/setProduct', [this.producToChangeStatus.id, this.producToChangeStatus]).then(() => {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Producto actualizado correctamente',
          }
        })
      },
      // async deleteProduct (productToDelete) {
      //   await this.$store.dispatch('product/deleteProduct', productToDelete.productKey).then(() => {
      //     this.snackbar = {
      //       show: true,
      //       color: 'green',
      //       text: 'Producto eliminado correctamente',
      //     }
      //   }).catch(() => {
      //     this.snackbar = {
      //       show: true,
      //       color: 'red',
      //       text: 'Error al eliminar el producto',
      //     }
      //   })
      // },
      addNewProduct () {
        this.forEditProduct = false
        this.dialodInvMod = true
        this.productToEdit = {
          codArticle: '',
          barcode: '',
          name: '',
          description: '',
          type: {
            codigo: '01',
            texto: 'BIEN',
          },
          quantity: '1',
          activeValue: '',
          activeWValue: '',
          activeValueWholesaler: '',
          activeWValueWholesaler: '',
          purchaseValue: '',
          purchaseWValue: '',
          productKey: '',
          completeData: '',
          tarifaiva: {
            codigo: '0',
            texto: '0%',
            valor: '0',
          },
          irbpnr: '',
          tarifaice: '',
          totalValue: '',
          inecCode: null,
          lstProviderPrice: [],
          branch: null,
        }
      },
      async closeInventoryModal () {
        this.dialodInvMod = false
      },
      async closeTransactionModal () {
        this.dialodTransaction = false
      },
      downLoadExcelFile () {
        const formatDecimalString = (value) => {
          if (typeof value === 'string' && value.includes(',')) {
            const [integerPart, decimalPart] = value.split(',')
            const roundedValue = Math.round(parseFloat(`${integerPart}.${decimalPart}`) * 100) / 100
            return roundedValue.toString().replace('.', ',')
          }
          return value
        }
        const data = []
        if (this.productsLst) {
          for (let i = 0; i < this.productsLst.length; i++) {
            data.push({
              'Cod. de articulo': this.productsLst[i].codArticle,
              'Cod. de barra': this.productsLst[i].barcode,
              Nombre: this.productsLst[i].name,
              Descripcion: this.productsLst[i].description,
              'Tipo de producto': this.productsLst[i].type.texto,
              'Valor sin IVA': formatDecimalString(this.productsLst[i].activeValue.toString().replace('.', ',')),
              Cantidad: this.productsLst[i].quantity,
              'Precio mayorista': this.productsLst[i].activeValueWholesaler ? formatDecimalString(this.productsLst[i].activeValueWholesaler.toString().replace('.', ',')) : 'No aplica',
              'Impuesto IVA': this.productsLst[i].tarifaiva.texto,
              'Precio unitario': formatDecimalString(this.productsLst[i].activeWValue.toString().replace('.', ',')),
              'Precio total': formatDecimalString(this.productsLst[i].totalValue.replace('.', ',')),
              'Val. Compra sin IVA': this.productsLst[i].purchaseValue ? formatDecimalString(this.productsLst[i].purchaseValue.toString().replace('.', ',')) : '0,00',
              'Val. Compra con IVA': this.productsLst[i].purchaseWValue ? formatDecimalString(this.productsLst[i].purchaseWValue.toString().replace('.', ',')) : '0,00',
            })
          }
          functions.JSONToCSVConvertor(data, 'Productos', true)
        }
      },
    },
  }
</script>
